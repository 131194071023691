.feature-section {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding-left: 10px;
    padding-bottom: 20px;
  }
  
  /* Adjustments for responsiveness */
  @media (max-width: 768px) {
    .feature-section {
      flex-direction: row;
      align-items: center;
    }
  }
  