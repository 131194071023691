.LandingPage {
    padding-bottom: 60px;
}

.LandingPage button {
    padding: 10px 20px;
    font-family: "Lexend", sans-serif;
    background-color: blue;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 10px;
    transition: background-color 0.3s ease;    
  }
  
  .LandingPage button:hover {
    background-color: darkblue;
  }