@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
.feature-card {
    text-align: left;
    background: #fff;
    margin-right: 10px;
    margin-left: 10px;
    width: calc(33.333% - 60px);
    max-width: 300px;
    font-family: "Manrope", serif;
    box-sizing: border-box;
  }
  
  .feature-icon {
    margin-bottom: 10px;
  }
  
  .feature-title {
    font-size: 1em;
    font-family: "Manrope", serif;
    margin: 0px;
    font-weight: normal;
    color: #0D141C;
  }
  
  .feature-description {
    font-size: 0.8em;
    font-family: "Manrope", serif;
    margin: 0px;
    font-weight: normal;
    color: #4A789C;
  }
  .feature-icon img {
    width: 100%; /* Make the image responsive within the container */
    height: auto; /* Maintain aspect ratio */
    max-width: 300px; /* Maximum icon size */
    margin: 0 auto; /* Center the icon */
  }
  
  @media (max-width: 1024px) {
    .feature-card {
      width: calc(50% - 20px); /* On smaller screens, switch to half-width cards */
    }
  }

  @media (max-width: 768px) {
    .feature-section {
      flex-direction: column;
      align-items: center;
    }
  
    .feature-card {
      width: 100%;
      margin: 10px 0;
    }
  }