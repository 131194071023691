.App-header {
    background-color: #fff; /* Or any other color you prefer */
    padding: 0 20px; /* Adjust padding as needed */
  }
  
  .header-content {
    display: flex;
    justify-content: space-between;
    font-family: 'Lexend', sans-serif;
    align-items: center;
    max-width: 1200px; /* Adjust the max-width as per your design */
    margin: 0 auto;
    padding: 10px 0;
  }
  
  .logo {
    font-weight: bold;
    font-size: 1.5em; /* Adjust logo size as needed */
  }
  
  .nav-links {
    list-style: none;
    display: flex;
    gap: 20px; /* Adjust the space between the links as needed */
    margin: 0;
    padding: 0;
  }
  
  .nav-links li {
    padding: 8px 0; /* Adjust padding for clickable area */
  }
  
  .nav-links li a {
    text-decoration: none;
    color: #333; /* Adjust the color as needed */
    font-size: 1em; /* Adjust font size as needed */
  }
  
  .nav-links li a:hover,
  .nav-links li a:focus {
    color: #000; /* Adjust hover/focus color as needed */
  }
  
  /* Responsive adjustments if needed */
  @media (max-width: 768px) {
    .header-content {
      flex-direction: row;
    }
  }
  