@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
.Body {
    margin-top: 50px;
}

.Body h1 {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: bold;
  font-style: normal;
  font-size: 4.5em;
  margin-bottom: 20px;
  margin-inline: 15%;
}
  
.audience {
  display: inline-block;
  animation: rotateUpwards 2s linear infinite;
}

.Body p {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  letter-spacing: 0.05em;
  font-style: normal;
  font-size: 1.25em;
  margin-bottom: 30px;
  margin-inline: 20%;
}

.turbocharge {
    padding-bottom: 40px;
    padding-top: 20px;
}

.turbocharge h1 {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-size: 3em;
  margin-bottom: 20px;
  margin-inline: 15%;
}
  
.turbocharge p {
  font-family: "Inter", sans-serif;
  font-size: 1.25em;
  margin-bottom: 30px;
  margin-inline: 25%;
}
  
.DemoButton {
    padding-top: 30px;
    padding-bottom: 50px;
}

.DemoButton button {
  padding: 10px 20px;
  font-family: "Lexend", sans-serif;
  background-color: blue;
  color: white;
  font-size: 1.2em;
  border: none;
  cursor: pointer;
  border-radius: 10px;
  transition: background-color 0.3s ease;
}

.DemoButton button:hover {
  background-color: darkblue;
}

.Closer h1 {
  font-family: "Lexend", sans-serif;
  font-optical-sizing: auto;
  font-weight: bold;
  font-style: bold;
  font-size: 2.5em;
  margin-bottom: 20px;
  margin-inline: 15%;
  padding-top: 50px;
  padding-bottom: 20px;
}

@media (max-width: 768px) {
  .Body h1 {
      font-size: 2.5em;
      margin-inline: 5%;
  }
  .turbocharge h1 {
    font-size: 2em;
    margin-inline: 5%;
    padding-bottom: 10px;
  }
  .Closer h1 {
    font-size: 2em;
    margin-inline: 0%;
  }
  .Body p {
      margin-inline: 5%;
      margin-bottom: 10px;
  }
  .turbocharge h3 {
    margin-inline: 5%;
  }
}

@keyframes rotateUpwards {
  0% {
    opacity: 0;
    transform: translateY(20%); /* Adjusted from 100% to a smaller value for less movement */
  }
  10% {
    opacity: 1;
    transform: translateY(0);
  }
}