.App-footer {
    background-color: #f0f0f0; /* Light grey background */
    color: #333; /* Dark text color */
    text-align: center;
    padding: 20px;
    font-size: 0.8em;
    border-top: 1px solid #e0e0e0; /* Subtle border top */
    margin-top: auto; /* Pushes the footer to the bottom */
  }
  
  /* Additional footer styles if needed */
  