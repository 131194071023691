.signup-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #333; /* Dark text for readability */
    font-family: 'Arial', sans-serif;
    padding-bottom: 60px;
}

.signup-form input {
    width: calc(80% - 20px); /* Full width minus padding */
    margin: 10px 0; /* Add space above and below */
    padding: 10px;
    background-color: #fff; /* Light background for input fields */
    border: 1px solid #ccc; /* Subtle border */
    border-radius: 10px; /* Optional: Adds rounded corners to the input fields */
    color: #333; /* Dark text */
    font-size: 16px; /* Slightly larger font for readability */
    font-family: "Manrope", sans-serif;
}

.signup-form button {
    padding: 10px 20px;
    font-family: "Lexend", sans-serif;
    background-color: blue;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 10px;
    transition: background-color 0.3s ease;

    margin-top: 10px; /* Add some space above the button */
}

.signup-form button:hover {
    background-color: darkblue; /* A darker green for hover effect */
}
